<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { computed, watch } from "vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import CopyIcon from "@magnit/icons/src/assets/icons/copy-24.svg";
import { usePlatformStore } from "~/store/platform";
import useToasts from "~/composables/useToasts";

const MY_MAGNIT_URL = "https://v1market.ru/";

defineProps<{ descriptionText?: string; }>();

const platformStore = usePlatformStore();
const toasts = useToasts();
const { copy, copied } = useClipboard({ legacy: true });
const isSafari = computed(
  () => platformStore.platformInfo.browserName === "safari",
);

watch(copied, (next) => {
  if (next) {
    toasts.success({ text: "Ссылка скопирована. Перейдите по ней из Safari" });
  }
});
</script>

<template>
  <div class="ios-pwa-instruction">
    <VText v-if="descriptionText" class="ios-pwa-instruction__subtitle">
      {{ descriptionText }}
    </VText>
    <div v-if="isSafari" class="ios-pwa-instruction__carousel">
      <div class="ios-pwa-instruction__slide-wrapper">
        <img src="/images/pwa_instruction/step_1_safari.webp" alt="step one">
      </div>
      <div class="ios-pwa-instruction__slide-wrapper">
        <img src="/images/pwa_instruction/step_2_safari.webp" alt="step two">
      </div>
      <div class="ios-pwa-instruction__slide-wrapper">
        <img
          src="/images/pwa_instruction/step_3_safari.webp"
          alt="step three"
        >
      </div>
    </div>
    <div v-else class="ios-pwa-instruction__carousel">
      <div class="ios-pwa-instruction__slide-wrapper">
        <img src="/images/pwa_instruction/step_1.webp" alt="step one">
      </div>
      <div class="ios-pwa-instruction__slide-wrapper">
        <img src="/images/pwa_instruction/step_2.webp" alt="step two">
      </div>
      <div class="ios-pwa-instruction__slide-wrapper">
        <img src="/images/pwa_instruction/step_3.webp" alt="step three">
      </div>
      <div class="ios-pwa-instruction__slide-wrapper">
        <img src="/images/pwa_instruction/step_4.webp" alt="step four">
      </div>
    </div>
    <div
      v-if="!isSafari"
      class="ios-pwa-instruction__url-copy"
      @pointerup.stop="copy(MY_MAGNIT_URL)"
    >
      <VText
        font="body-large-accent"
        class="ios-pwa-instruction__url-copy-text"
      >
        Скопировать ссылку <CopyIcon />
      </VText>
    </div>
  </div>
</template>

<style lang="postcss">
.ios-pwa-instruction {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__subtitle {
    margin: 0 0 var(--pl-unit-x4);
  }

  &__carousel {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    overscroll-behavior-x: contain;
  }

  &__slide-wrapper {
    scroll-snap-align: center;
    width: 240px;
    height: 320px;
    margin-left: var(--pl-unit-x3);
    flex-shrink: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  img {
    display: block;
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  &__url-copy {
    border-radius: var(--pl-field-radius-m);
    border: 1px dashed var(--pl-push-control-border-checkbox-off-default);
    background: var(--pl-color-tooltip-background-primary);
    cursor: pointer;
    padding: var(--pl-unit-x4);
    margin-top: var(--pl-unit-x4);

    &-text {
      display: inline-flex;
      gap: var(--pl-unit-x2);
    }
  }
}
</style>
